<template>
  <div class="box">
    <div class="addBank_title">添加银行卡</div>


    <div class="bind_after">
      <div class="bind_after_card" v-for="(item, index)  in list" :key="index">
        <div class="card_name">
          <p class="zfb">{{ item.bank_of_deposit }}</p>
          <p class="default" v-if="item.type == 2">默认</p>
        </div>
        <div class="zfb_num">
          <p class="num">{{ editCardNum(item.bank_card_number) }}</p>
          <img @click="editCard(item)" src="@/assets/images/bindAccounts/edit.png" alt="">
          <img @click="deleteCard(item)" src="@/assets/images/addBankCard/del.png" alt="">
        </div>
      </div>
      <div class="addId" @click="go()">
        <button>
          <img src="@/assets/images/addBankCard/add.png" alt="">
        </button>
        <p>点击添加银行卡</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getCardList, cardDelete } from '@/untils/distubritionIndex.js'
import { Dialog } from 'vant';
export default {
  name: 'addBankCard',
  data() {
    return {
      value: "",
      sms: "",
      showBind: true,
      page: 0,
      list: []
    };
  },
  computed: {

  },
  created() {
    this.getList()
  },
  mounted() {

  },
  methods: {
    //删除银行卡
    deleteCard(item) {
      Dialog.confirm({
        title: '删除银行卡',
        message: '确认删除此张银行卡吗？',
      })
        .then(() => {
          let data = {
            uuid: item.uuid,
            user_id: JSON.parse(sessionStorage.getItem('UserInfo')).id
          }
          cardDelete({ data }).then(res => {
            this.$toast(res.data.msg)
            this.page = 0
            getList()
          })

        })
        .catch(() => {
        });

    },

    //修改银行卡
    editCard(item) {
      // this.$router.push('/addBankCardInfo')
      //跳转时页面
      this.$router.push({
        name: 'addBankCardInfo',
        params: {
          item: item
        }
      })
    },
    go() {
      this.$router.push('/addBankCardInfo')
    },
    editCardNum(num) {
      return num.replace(/.(?=.{4})/g, "*")
    },

    // 获取银行卡列表
    getList() {
      let data = {
        uuid: JSON.parse(sessionStorage.getItem('UserInfo')).uuid,
        page: ++this.page
      }
      getCardList({ data }).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data.data
        }

      })
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;

  .addBank_title {
    width: 100%;
    height: 44px;
    line-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333;
    font-weight: 550;
    background-color: #fff;
  }

  .addBank_card {
    width: 345px;
    height: 398px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(5, 54, 102, 0.1);
    margin: 20px auto;

    /deep/ .van-cell {
      height: 52px;
      line-height: 40px;
      // border: 1px solid red;
    }

    /deep/ .van-field__label {
      font-size: 14px;
      color: #666666;
      width: auto;
    }

    /deep/.van-field__control {
      font-size: 14px;
      font-weight: 700;
    }

    /deep/.van-field__control::-webkit-input-placeholder {
      color: #999999;
      font-weight: normal;
    }

    /deep/.van-button {
      background-color: #1E6FFF;
      width: 88px;
      height: 28px;
      border-radius: 60px;
    }
  }

  .btn {
    text-align: center;
    margin-top: 41px;

    button {
      width: 307px;
      height: 43px;
      border-radius: 21px;
      background: #1E6FFF;
      font-size: 18px;
      font-weight: 500;
      line-height: 13px;
      color: #fff;
    }
  }

  .bind_after {
    .bind_after_card {
      margin: 25px auto;
      width: 326px;
      height: 122px;
      border-radius: 10px;
      box-sizing: border-box;
      background: linear-gradient(141deg, #1088FE 34%, #1088FE 34%, #19A2F0 103%, #19A2F0 103%);
      // background: url('~@/assets/images/bindAccounts/zfb_bg.png') no-repeat;
      position: relative;
      overflow: hidden;
      box-shadow: 1px 4px 8px 0px rgb(185, 185, 185);

      .zfb_bg {
        position: absolute;
        top: 33px;
        left: 235px;
      }

      .zfb {
        font-size: 16px;
        margin-top: 20px;
        margin-left: 22px;
        color: #fff;
      }

      .card_name {
        display: flex;
        justify-content: space-between;

        .default {
          margin-top: 13px;
          font-size: 14px;
          background-color: #1E6FFF;
          width: 52px;
          height: 28px;
          font-weight: 700;
          line-height: 28px;
          text-align: center;
          border-radius: 60px;
          color: #fff;
          margin-right: 17px;
          font-weight: 350;
          box-shadow: 1px 1px 3px 0px rgb(2, 45, 124);

        }
      }

      .zfb_num {
        margin-top: 48px;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        color: #fff;

        .num {
          margin-left: 21px;
        }

        img:nth-child(2) {
          margin-left: 70px;
        }

        img:nth-child(3) {
          margin-right: 25px;
        }
      }
    }
  }

  .addId {
    margin: 0 auto;
    text-align: center;
    width: 326px;
    height: 122px;
    border: 1px dashed #ACD3FF;
    margin-top: 24px;
    border-radius: 10px;

    button {
      border-radius: 50%;
      margin-top: 20px;
    }

    p {
      font-size: 14px;
      line-height: 24px;
    }

  }

  .addId:active {
    background-color: #fafafa;
    // transition:ease-in 500ms;


  }
}
</style>